import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'
import SaaSCTA from '@/components/SaaSCTA/SaaSCTA.vue'

export default {
  name: 'WriterPaid',
  data () {
    return {
      isLoading: {
        contentplans: false,
        clusterer: false,
        writer: false,
        articles: false
      },

      keywords: null,
      domain: null,
      domainRules: [
        // value can be empty
        value => !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,}\/?$/.test(value) || 'Invalid domain'
      ],

      countries,
      country: 'USA',
      languages,
      language: 'en',
      serpOverlap: 2,

      interval: null,
      tableHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Slug', value: 'slug' },
        { text: 'Type', value: 'type' },
        { text: 'Created', value: 'status_updated_at' }
      ],
      clusters: null,
      newContentplanId: null,
      newArticleId: null,
      socket: null,
      reconnectAttempts: 0,
      maxReconnectAttempts: 5,
      reconnectTimeout: null,
      writerProgress: {
        keywordResearch: null,
        clustering: null,
        briefing: null,
        researching: null,
        writing: null,
        editing: null
      },
      selectedArticle: null,
      selectedArticleContent: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'contentplans',
      'websocketUrl',
      'articles'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    hasActiveSubscription () {
      if (!this.activeWorkspace) return false
      const activeSubscription = this.activeWorkspace.type === 'saas' && this.activeWorkspace.subscription_active
      if (activeSubscription) this.keywordLimit = this.keywordLimitPaid
      return activeSubscription
    },
    hasActiveTrial () {
      if (!this.activeWorkspace) return false
      const workspaceCreated = new Date(this.activeWorkspace.created_at).getTime() // in UTC
      const now = new Date().getTime() + new Date(new Date().getTimezoneOffset() * 60 * 1000).getTime() // time now in UTC
      const trialLength = 24 * 60 * 60 * 1000 // 24 hours
      const activeTrial = this.activeWorkspace.type === 'saas' && !this.activeWorkspace.subscription_active && now - workspaceCreated < trialLength
      if (this.showTrialPopup === null) this.showTrialPopup = activeTrial
      return activeTrial
    },
    allDone () {
      return Object.values(this.writerProgress).every(status => status === 'done') || Object.values(this.writerProgress).every(status => status === null)
    }
  },
  methods: {
    ...mapActions([
      'connectWebSocket',
      'planAndKeywords',
      'loadArticlesSaas'
    ]),
    async loadData () {
      this.isLoading.contentplans = true
      try {
        await this.loadArticlesSaas({
          workspaceId: this.workspaceId
        })
      } catch (e) {
        console.error(e)
      }

      this.isLoading.contentplans = false
    },
    async runClusterer () {
      try {
        if (!this.keywords?.length) return
        this.writerProgress.keywordResearch = 'inProgress'

        const data = await this.planAndKeywords({
          contentplanName: 'AI Writer - ' + this.keywords,
          workspaceId: this.workspaceId,
          language: this.language,
          country: this.country,
          keywords: [{ keyword: this.keywords }],
          type: 'saas-writer'
        })
        this.newContentplanId = data.contentplanId

        this.writerProgress.keywordResearch = 'done'
        this.writerProgress.clustering = 'inProgress'

        this.sendMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.newContentplanId,
            runUpdating: false,
            runGrouping: true,
            runMapping: false,
            runWriting: true,
            selectBy: 'all'
          }
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.writer = false
    },
    async connectWebSocket (body) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.socket = new WebSocket(this.websocketUrl)
      }

      this.socket.onopen = () => {
        this.reconnectAttempts = 0
        if (body) {
          this.socket.send(JSON.stringify(body))
        }
      }

      this.socket.onmessage = (event) => {
        this.websocketMessageHandler(event)
      }

      this.socket.onerror = (error) => {
        console.error('WebSocket error: ', error.message)
      }

      this.socket.onclose = (event) => {
        console.log('WebSocket closed with code:', event.code)
        if (event.code === 1006 && this.reconnectAttempts < this.maxReconnectAttempts) {
          this.reconnectAttempts++
          const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 10000)
          this.reconnectTimeout = setTimeout(() => {
            this.connectWebSocket(body)
          }, delay)
        }
      }
    },
    async sendMessage (body) {
      try {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          // console.log('WebSocket connection not established, opening...')
          await this.connectWebSocket(body)
        } else {
          // console.log('WebSocket connection open, sending message...')
          this.socket.send(JSON.stringify(body))
        }
      } catch (err) {
        console.error(err)
      }
    },
    async websocketMessageHandler (event) {
      const eventData = JSON.parse(event.data)
      const message = eventData?.messages?.[0]?.content
      console.log('Received websocket message', message)

      if (message === 'Grouping step completed') {
        this.writerProgress.clustering = 'done'
        this.writerProgress.briefing = 'inProgress'
      }
      if (message === 'Brief completed') {
        this.writerProgress.briefing = 'done'
        this.writerProgress.researching = 'inProgress'
      }
      if (message === 'Research completed') {
        this.writerProgress.researching = 'done'
        this.writerProgress.writing = 'inProgress'
      }
      if (message === 'Draft completed') {
        this.writerProgress.writing = 'done'
        this.writerProgress.editing = 'inProgress'
      }
      if (message === 'Editor completed') {
        this.writerProgress.editing = 'done'
        await this.loadData()
      }
      if (message === 'Publish completed') this.writerProgress.publishing = 'done'
    },
    openArticle (article) {
      this.selectedArticle = article // Set the selected article
      this.selectedArticleContent = article.final // Set the selected article content
    },
    copyArticle (article) {
      if (!article) return

      const markdown = article.final
      // Add the markdown to the clipboard
      navigator.clipboard.writeText(markdown)
    },
    async closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
      this.selectedArticleContent = null // Reset the selected article content
      await this.loadData() // Reload
    }
  },
  async mounted () {
    await this.loadData()
  },
  beforeDestroy () {
    if (this.socket) {
      this.socket.close()
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout)
    }
  },
  components: {
    MarkdownEditor,
    SaaSCTA
  }
}
