import SaaSCTA from '@/components/SaaSCTA/SaaSCTA.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ResearchPaid',
  data () {
    return {
      isLoading: {
        research: false
      }
    }
  },
  computed: {
    ...mapGetters(['activeWorkspace']),
    hasActiveSubscription () {
      if (!this.activeWorkspace) return false
      const activeSubscription = this.activeWorkspace.type === 'saas' && this.activeWorkspace.subscription_active
      if (activeSubscription) this.keywordLimit = this.keywordLimitPaid
      return activeSubscription
    },
    hasActiveTrial () {
      if (!this.activeWorkspace) return false
      const workspaceCreated = new Date(this.activeWorkspace.created_at).getTime() // in UTC
      const now = new Date().getTime() + new Date(new Date().getTimezoneOffset() * 60 * 1000).getTime() // time now in UTC
      const trialLength = 24 * 60 * 60 * 1000 // 24 hours
      const activeTrial = this.activeWorkspace.type === 'saas' && !this.activeWorkspace.subscription_active && now - workspaceCreated < trialLength
      if (this.showTrialPopup === null) this.showTrialPopup = activeTrial
      return activeTrial
    }
  },
  components: {
    SaaSCTA
  }
}
