import { mapGetters, mapActions } from 'vuex'
import parseData from '@/utils/parseData'
import languages from '@/utils/_languages.json'
import countries from '@/utils/_countries.json'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  name: 'ContentplanSettings',
  data () {
    return {
      activeTab: 'general',
      tabs: [
        { id: 'general', label: 'General Settings', icon: 'mdi-cog' },
        { id: 'integrations', label: 'Integrations', icon: 'mdi-connection' },
        { id: 'ai', label: 'AI Settings', icon: 'mdi-robot' }
      ],
      isLoading: {
        contentplan: false,
        saving: false
      },
      settings: [
        { label: 'Name', id: 'name', value: '', disabled: false },
        { label: 'Target country', id: 'country_crawl', value: '', options: [...countries], disabled: false },
        { label: 'Content language', id: 'language', value: '', options: [...languages], disabled: false },
        {
          label: 'Minimum SERP overlap for clustering',
          id: 'minimum_score',
          value: '',
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          disabled: false
        }
      ],

      textFields: [
        { label: 'About your company', id: 'about', value: '', disabled: false, placeholder: 'What does your company provide, for whom and what makes you different?' },
        { label: 'Style guide', id: 'style_guide', value: '', disabled: false, placeholder: 'What would you like your content to look like? Formatting, tone, etc.' }
      ],

      integrationsAnalytics: [
        { label: 'Google Search Console', id: 'search_console', value: false, disabled: false, route: 'SearchConsoleIntegration' },
        { label: 'Google Analytics', id: 'google_analytics', value: false, disabled: false, route: 'GoogleAnalyticsIntegration' },
        { label: 'Google Ads (coming soon)', id: 'google_ads', value: false, disabled: true, route: '' }
      ],

      integrationsCMS: [
        { label: 'WordPress', id: 'wordpress', value: false, disabled: false, route: 'WordpressIntegration' },
        { label: 'Webflow (coming soon)', id: 'webflow', value: false, disabled: true, route: '' }
      ],
      dialog: false,
      files: {
        lightLogo: null,
        darkLogo: null,
        cssFile: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'activeContentplan',
      'activeIntegration',
      'apiUrl',
      'googleAnalyticsIntegration',
      'searchConsoleIntegration',
      'wordpressIntegration',
      'notionIntegration'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    notionUrl () {
      const redirectUri = encodeURIComponent(`${window.location.origin}/notion-integration`)
      const state = encodeURIComponent(`contentplanId=${this.contentplanId.toString()}`)
      return `https://api.notion.com/v1/oauth/authorize?client_id=170d872b-594c-8000-94e9-0037491c0013&response_type=code&owner=user&redirect_uri=${redirectUri}&state=${state}`
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'loadContentplan',
      'updateContentplan',
      'getIntegrations'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      document.title = 'Contentplan settings | ContentGecko'

      try {
        await this.getIntegrations({ contentplanId: this.contentplanId })
        const contentplanData = await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
        this.mapSettings(contentplanData)
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.contentplan = false
    },
    mapSettings (data) {
      this.settings.forEach(setting => {
        setting.value = data[setting.id]
      })
      this.textFields.forEach(textField => {
        textField.value = data[textField.id]
      })
    },
    async saveSettings () {
      this.isLoading.saving = true

      try {
        const params = this.settings.reduce((acc, item) => {
          acc[item.id] = item.value
          return acc
        }, {})

        // add text fields to params
        params.about = this.textFields[0].value
        params.style_guide = this.textFields[1].value

        await this.updateContentplan({
          contentplanId: this.contentplanId,
          ...params
        })
        await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.error(error)
      }

      this.isLoading.saving = false
    },
    async handleConnect (id) {
      let nextUrl = ''
      let scope = ''

      if (id === 'google_analytics') {
        nextUrl = window.location.href.replace('/settings', '/ga-integration')
        scope = 'all'
      }

      if (id === 'search_console') {
        nextUrl = window.location.href.replace('/settings', '/gsc-integration')
        scope = 'search-console'
      }

      const params = `next=${nextUrl}&contentplanId=${this.contentplanId}&scope=${scope}`
      const authUrl = window.location.origin + '/auth/in?' + params
      window.location.href = authUrl
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    MarkdownEditor
  }
}
