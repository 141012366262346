import { mapGetters, mapActions } from 'vuex'

import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'
import { statuses, funnel, types } from '@/utils/clusterVariables.js'

export default {
  name: 'ContentEditor',
  data () {
    return {
      isLoading: {
        articles: false,
        setStatus: null,
        uploadCluster: null,
        uploadAll: false
      },
      isLoadingPriority: null,
      savingFinalDraft: false,
      saveTimeoutFinalDraft: null,
      saveDelay: 1000,

      tableHeaders: [
        { text: 'Title', value: 'final' },
        { text: 'Slug', value: 'slug' },
        { text: 'Last updated', value: 'last_updated' },
        { text: 'Published', value: 'action' }
      ],
      tabs: [
        { text: 'Unpublished', value: 'unpublished' },
        { text: 'Published', value: 'published' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'All', value: 'all' }
      ],
      activeTab: 0,

      selectedArticle: null,
      selectedArticleContent: null,
      statuses,
      funnel,
      types
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async activeTab () {
      await this.loadData()
    },
    selectedArticle: {
      handler (newVal) {
        if (newVal) {
          window.addEventListener('keydown', this.handleEscKey)
        } else {
          window.removeEventListener('keydown', this.handleEscKey)
        }
      }
    },
    selectedArticleContent: {
      handler (newValue) {
        if (!this.savingFinalDraft && newValue !== this.selectedArticle?.final) {
          console.log('Saving final draft...')
          this.savingFinalDraft = true
          clearTimeout(this.saveTimeoutFinalDraft)
          this.saveTimeoutFinalDraft = setTimeout(() => {
            this.saveContent(this.selectedArticle?.article_id)
            this.savingFinalDraft = false
          }, this.saveDelay)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesContent',
      'updateArticles',
      'initiateCreateContent'
    ]),
    async loadData () {
      this.isLoading.articles = true
      document.title = 'ContentEditor | ContentGecko'

      try {
        await Promise.all([
          this.loadArticlesContent({
            contentplanId: this.contentplanId,
            statusFilter: this.tabs[this.activeTab].value
          })
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.articles = false
    },
    editArticle (article) {
      this.selectedArticle = article // Set the selected article
      this.selectedArticleContent = article.final // Set the selected article content
    },
    copyArticleMarkdown (article) {
      if (!article) return

      const markdown = article.final
      // Add the markdown to the clipboard
      navigator.clipboard.writeText(markdown)
    },
    copySlug (article) {
      if (!article) return

      const slug = article.slug
      // Add the slug to the clipboard
      navigator.clipboard.writeText(slug)
    },
    updateFinalDraft (newContent) {
      if (this.selectedArticle) {
        this.selectedArticle.final = newContent // Update the final draft content
        this.selectedArticleContent = newContent // Update the selected article content
      }
    },
    async saveContent (articleId) {
      if (!articleId) return

      this.isLoading.saveContent = true
      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateFinalDraft',
        articleIds: [articleId],
        final: this.selectedArticleContent
      })

      this.isLoading.saveContent = false
    },
    async closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
      this.selectedArticleContent = null // Reset the selected article content
      await this.loadData() // Reload
    },
    getStatusColor (status) {
      const statusObj = this.statuses.find(s => s.value === status)
      return statusObj ? statusObj.color : null
    },
    getFunnelColor (funnel) {
      const funnelObj = this.funnel.find(f => f.value === funnel)
      return funnelObj ? funnelObj.color : null
    },
    getTypeColor (type) {
      const typeObj = this.types.find(t => t.value === type)
      return typeObj ? typeObj.color : null
    },
    async setStatus (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setStatus = clusterId

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateStatus',
          articleIds: [clusterId],
          status: value
        })

        // close editor
        this.closeEditor()
      } finally {
        this.isLoading.setStatus = false
      }
    },
    async setFunnel (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateFunnel',
          articleIds: [clusterId],
          funnel: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setFunnel = false
      }
    },
    async setType (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setType = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateType',
          articleIds: [clusterId],
          type: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setType = false
      }
    },
    async setPriority (clusterId, value) {
      if (!value) return
      if (!clusterId) return
      this.isLoadingPriority = clusterId

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updatePriority',
          articleIds: [clusterId],
          priority: value
        })
        await this.loadData()
      } finally {
        this.isLoadingPriority = null
      }
    },
    handleEscKey (event) {
      if (event.key === 'Escape') {
        this.closeEditor()
      }
    },
    async handleUploadCluster (clusterId) {
      if (!clusterId) return

      this.isLoading.uploadCluster = clusterId

      try {
        await this.initiateCreateContent({
          contentplanId: this.contentplanId,
          selectBy: 'cluster',
          clusterId: clusterId,
          skipGenerateBrief: true,
          skipSaveSlugAndSynopsis: true,
          skipAddInternalLinkSuggestions: true,
          skipResearch: true,
          skipGenerateDraft: true,
          skipEditor: true,
          skipPublish: false
        })
      } finally {
        this.isLoading.uploadCluster = null
      }
    },
    async handleUploadAll () {
      this.isLoading.uploadAll = true

      try {
        await this.initiateCreateContent({
          contentplanId: this.contentplanId,
          selectBy: 'shortlist',
          skipGenerateBrief: true,
          skipSaveSlugAndSynopsis: true,
          skipAddInternalLinkSuggestions: true,
          skipResearch: true,
          skipGenerateDraft: true,
          skipEditor: true,
          skipPublish: false
        })
      } finally {
        this.isLoading.uploadAll = false
      }
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    MarkdownEditor
  }
}
