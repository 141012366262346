import { mapActions, mapGetters } from 'vuex'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'

export default {
  name: 'WriterFree',
  data () {
    return {
      isLoading: {
        contentplans: false,
        clusterer: false,
        writer: false,
        articles: false,
        user: false,
        email: false
      },

      keywords: null,

      countries,
      country: 'USA',
      languages,
      language: 'en',
      serpOverlap: 2,

      interval: null,
      clusters: null,
      newContentplanId: null,
      newArticleId: null,
      socket: null,
      writerProgress: {
        keywordResearch: null,
        clustering: null,
        briefing: null,
        researching: null,
        writing: null,
        editing: null
      },
      email: null,
      emailSent: false,
      emailSaved: false,
      emailRules: [
        value => !value || /.+@.+\..+/.test(value) || 'Invalid email'
      ]
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'websocketUrl'
    ]),
    allStatusesNull () {
      return Object.values(this.writerProgress).every(status => status === null)
    },
    allStatusesDone () {
      return Object.values(this.writerProgress).every(status => status === 'done')
    }
  },
  methods: {
    ...mapActions([
      'connectWebSocket',
      'planAndKeywords',
      'loadArticlesSaas',
      'postUser',
      'writerSendEmail'
    ]),
    async loadData () {
    },
    async runClusterer () {
      try {
        if (!this.keywords?.length) return
        this.writerProgress.keywordResearch = 'inProgress'

        const data = await this.planAndKeywords({
          contentplanName: 'AI Writer - ' + this.keywords,
          workspaceId: this.workspaceId,
          language: this.language,
          country: this.country,
          keywords: [{ keyword: this.keywords }],
          type: 'saas-writer'
        })
        this.newContentplanId = data.contentplanId

        this.writerProgress.keywordResearch = 'done'
        this.writerProgress.clustering = 'inProgress'

        this.sendMessage({
          action: 'runOrchestrator',
          message: {
            contentplanId: this.newContentplanId,
            runUpdating: false,
            runGrouping: true,
            runMapping: false,
            runWriting: true,
            selectBy: 'all',
            sendEmail: true
          }
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.writer = false
    },
    async connectWebSocket (body) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        // console.log('WebSocket connection not established, opening...')
        this.socket = new WebSocket(this.websocketUrl)
      }

      this.socket.onopen = () => {
        // console.log('WebSocket connection established', this.socket)
        if (body) {
          this.socket.send(JSON.stringify(body))
        }
      }

      // Handle incoming messages.
      this.socket.onmessage = (event) => {
        // console.log('Message received:', event)
        this.websocketMessageHandler(event)
      }

      this.socket.onerror = (error) => {
        console.error('WebSocket error: ', error.message)
      }
    },
    async sendMessage (body) {
      try {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          // console.log('WebSocket connection not established, opening...')
          await this.connectWebSocket(body)
        } else {
          // console.log('WebSocket connection open, sending message...')
          this.socket.send(JSON.stringify(body))
        }
      } catch (err) {
        console.error(err)
      }
    },
    async websocketMessageHandler (event) {
      const eventData = JSON.parse(event.data)
      const message = eventData?.messages?.[0]?.content
      console.log('Received websocket message', message)

      if (message === 'Grouping step completed') {
        this.writerProgress.clustering = 'done'
        this.writerProgress.briefing = 'inProgress'
      }
      if (message === 'Brief completed') {
        this.writerProgress.briefing = 'done'
        this.writerProgress.researching = 'inProgress'
      }
      if (message === 'Research completed') {
        this.writerProgress.researching = 'done'
        this.writerProgress.writing = 'inProgress'
      }
      if (message === 'Draft completed') {
        this.writerProgress.writing = 'done'
        this.writerProgress.editing = 'inProgress'
      }
      if (message === 'Editor completed') {
        this.writerProgress.editing = 'done'
      }
      if (message === 'Publish completed') this.writerProgress.publishing = 'done'
    },
    async addNewUser () {
      this.isLoading.user = true
      let userId
      try {
        userId = await this.postUser({
          contentplanId: this.newContentplanId,
          email: this.email
        })
      } catch (err) {
        console.log(err)
      }
      this.emailSaved = true
      this.isLoading.user = false
      return userId
    },
    async addNewUserAndSendEmail () {
      this.isLoading.email = true
      try {
        const userId = await this.addNewUser()
        await this.writerSendEmail({
          userId,
          contentplanId: this.newContentplanId
        })
      } catch (err) {
        console.log(err)
      }
      this.emailSent = true
      this.isLoading.email = false
    },
    reset () {
      this.keywords = null
      this.newContentplanId = null
      this.email = null
      this.emailSent = false
      this.emailSaved = false
      this.writerProgress = {
        keywordResearch: null,
        clustering: null,
        briefing: null,
        researching: null,
        writing: null,
        editing: null
      }
    }
  },
  async mounted () {
    await this.loadData()
  }
}
