import { mapActions, mapGetters } from 'vuex'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  data () {
    return {
      isLoading: {
        agent: false,
        writeAll: false
      },
      agents: {
        brief: { name: 'SEO specialist', color: 'blue' },
        research: { name: 'Researcher', color: 'blue' },
        draft: { name: 'Writer', color: 'blue' },
        editor: { name: 'Editor', color: 'blue' }
      },
      userMessage: '',
      systemMessage: 'I am Gecko, AI co-pilot designed to help you produce the best page for this keyword cluster. I have access to all the data you need - keywords, your pages, competitor pages and SERP data. Ask me anything!',
      messages: [],
      socket: null
    }
  },
  mounted () {
    this.connectWebSocket()
  },
  computed: {
    ...mapGetters([
      'websocketUrl'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    filteredMessages () {
      return this.messages?.filter(m => m.role !== 'function') || []
    }
  },
  methods: {
    ...mapActions([
      'initiateCreateContent'
    ]),
    async sendMessage (body) {
      try {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          // console.log('WebSocket connection not established, opening...')
          await this.connectWebSocket(body)
        } else {
          // console.log('WebSocket connection open, sending message...')
          this.socket.send(JSON.stringify(body))
        }
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    },
    async connectWebSocket (body) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        // console.log('WebSocket connection not established, opening...')
        this.socket = new WebSocket(this.websocketUrl)
      }

      this.socket.onopen = () => {
        // console.log('WebSocket connection established', this.socket)
        if (body) {
          this.socket.send(JSON.stringify(body))
        }
      }

      // Handle incoming messages.
      this.socket.onmessage = (event) => {
        // console.log('Message received:', event)
        const response = JSON.parse(event.data)
        if (response?.messages) {
          // console.log('response:', response.messages[0]?.content)
          // append to last assistant message
          if (this.messages[this.messages.length - 1]?.loading) {
            // remove loading message and replace with empty assistant message
            this.messages.pop()
            this.messages.push({ role: 'assistant', content: '' })
          }
          this.messages[this.messages.length - 1].content += response.messages[0]?.content
        }
      }

      this.socket.onerror = (error) => {
        console.error('WebSocket error: ', error.message)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    },
    async copyToClipboard (text) {
      await navigator.clipboard.writeText(text)
    },
    async runAgent (agentType) {
      this.isLoading.agent = agentType
      // push user message
      this.messages.push({ role: 'user', content: `*Run ${agentType} agent*` })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'callAgent',
          message: {
            clusterId: this.articleId,
            agent: agentType
          }
        })
      } catch (err) {
        console.error(err)
        console.log('Error')
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.agent = false
    },
    async generateAll () {
      this.isLoading.writeAll = true

      try {
        await this.initiateCreateContent({
          contentplanId: this.contentplanId,
          selectBy: 'cluster',
          clusterId: this.articleId,
          skipPublish: true
        })
      } catch (error) {
        console.log(error)
      }

      this.messages.push({ role: 'assistant', content: 'Running all agents! 🏃 Check back in a few minutes for your content.' })
      this.isLoading.writeAll = false
    },
    async sendChatMessage () {
      // console.log('sendChatMessage', this.userMessage)
      // push user message
      this.messages.push({ role: 'user', content: this.userMessage })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'sendMessage',
          message: {
            contentplanId: this.contentplanId,
            clusterId: this.articleId,
            pageId: this.pageId,
            messages: this.messages.filter(m => m.content !== '')
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    }
  },
  props: {
    pageId: {
      type: Number
    }
  },
  components: {
    MarkdownEditor
  }
}
