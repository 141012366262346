import Editor from '@toast-ui/editor'
import '@toast-ui/editor/dist/toastui-editor.css'

export default {
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Write here...'
    },
    minHeight: {
      type: String,
      default: 'fit-content'
    },
    maxHeight: {
      type: String,
      default: 'auto'
    },
    showSaveStatus: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    defaultMode: {
      type: String,
      default: 'markdown'
    }
  },
  data () {
    return {
      editor: null,
      currentMode: this.defaultMode
    }
  },
  methods: {
    toggleEditMode (event) {
      if (this.readonly) return

      // Check if it's cmd+s (Mac) or ctrl+s (Windows)
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault() // Prevent default save behavior
        event.stopPropagation() // Stop event from reaching the editor
        this.currentMode = this.currentMode === 'markdown' ? 'wysiwyg' : 'markdown'
        this.editor.changeMode(this.currentMode)
      }
    },
    handleChange () {
      const newValue = this.editor.getMarkdown()
      this.$emit('input', newValue)
    },
    setMarkdown (value) {
      if (this.editor) {
        this.editor.setMarkdown(value)
      }
    },
    initEditor () {
      this.editor = new Editor({
        el: this.$el.querySelector('#editor'),
        initialValue: this.value,
        minHeight: this.minHeight,
        height: this.maxHeight,
        placeholder: this.placeholder,
        initialEditType: this.defaultMode,
        previewStyle: 'tab',
        hideModeSwitch: true,
        toolbarItems: [],
        useCommandShortcut: false,
        viewer: this.readonly
      })

      // Add event listener directly to the editor instance
      this.editor.on('change', () => {
        const newValue = this.editor.getMarkdown()
        this.$emit('input', newValue)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initEditor()
      // Add keyboard event listener
      document.addEventListener('keydown', this.toggleEditMode)
    })
  },
  beforeDestroy () {
    if (this.editor) {
      document.removeEventListener('keydown', this.toggleEditMode)
      this.editor.destroy()
    }
  }
}
