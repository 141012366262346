import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SaaSCTA',
  data () {
    return {
      isLoading: { checkout: false },
      showTrialPopup: false
    }
  },
  computed: {
    ...mapGetters(['activeWorkspace']),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    }
  },
  methods: {
    ...mapActions(['createStripeCheckoutSession']),
    async redirectToCheckout () {
      this.isLoading.checkout = true
      const response = await this.createStripeCheckoutSession({ workspaceId: this.workspaceId })
      const redirectURL = response && response.url
      this.isLoading.checkout = false
      if (redirectURL) {
        window.location.href = redirectURL
      }
    }
  },
  mounted () {
    // Auto-show trial popup if user has an active trial
    if (this.hasActiveTrial) {
      this.showTrialPopup = true
    }
  }
}
