import { mapActions, mapGetters } from 'vuex'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'
import SaaSCTA from '@/components/SaaSCTA/SaaSCTA.vue'

export default {
  name: 'PaidClusterer',
  data () {
    return {
      isLoading: {
        clusterer: false,
        contentplans: false,
        checkout: false,
        recluster: false
      },

      keywordLimit: 1000,
      keywordLimitPaid: 20000,

      keywords: null,
      keywordRules: [
        value => !value || value?.split(/[\r\n\t]+/)?.length <= this.keywordLimit || `Enter less than ${this.keywordLimit + 1} keywords`,
        value => !value || value?.split(/[\r\n\t]+/)?.length > 1 || 'Enter at least 2 keywords'
      ],
      domain: null,
      domainRules: [
        // value can be empty
        value => !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.)+[a-zA-Z]{2,}\/?$/.test(value) || 'Invalid domain'
      ],

      form: null,
      countries,
      languages,
      country: 'USA',
      language: 'en',
      serpOverlap: 2,

      interval: null,
      tableHeaders: [
        { text: 'Domain', value: 'domain' },
        { text: 'Country', value: 'country_crawl' },
        { text: 'Language', value: 'language' },
        { text: 'SERP overlap', value: 'minimum_score' },
        { text: 'Spreadsheet link', value: 'csv_url', sortable: false },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      reclusterContentplanId: null,
      reclusterOverlap: 2,
      reclusterShowDialog: false,
      showTrialPopup: null
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace',
      'contentplans'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    keywordsHint () {
      if (!this.keywords?.length) return 'Enter a list of keywords, one per line'
      if (/,+/u.test(this.keywords)) return 'Enter one keyword per line. Don\'t use commas to seperate keywords'
      if (!/^[\p{L}\p{N}\s\n]+$/u.test(this.keywords)) return 'Keywords with punctuation or special symbols will be ignored'
      return ''
    },
    isRusLocation () {
      return this.country === 'RUS' || this.country === 'BLR'
    },
    isRusDomain () {
      if (!this.form) return false
      return /(ru|by)\/?$/u.test(this.domain)
    },
    hasCleanKeywords () {
      const keywordsArray = this.keywords?.split(/[\r\n\t]+/)?.filter(k => k)
      if (!keywordsArray?.length) return true
      const keywordsCleaned = keywordsArray?.map(k => k.trim().toLowerCase()).filter(k => !!k).filter(k => {
        const regex = /^[\p{L}\p{N}\s]+$/u
        return regex.test(k)
      })

      return keywordsCleaned && keywordsCleaned.length > 0
    },
    errorMessage () {
      if (this.isRusLocation) return 'We don\'t support Russia or Belarus.'
      if (!this.hasCleanKeywords) return 'Please enter keywords without punctuation or special symbols'
      if (this.isRusDomain) return 'We don\'t support Russian or Belarussian domains.'
      if (this.countKeywords() > this.keywordLimit) return `You can cluster up to ${this.keywordLimit} keywords at a time.`
      if (this.countKeywords() > this.activeWorkspace?.remaining_monthly_credits) return 'You don\'t have enough monthly credits left.'
      return null
    },
    contentplanHistory () {
      if (!this.contentplans?.length) return []
      return this.contentplans.filter(cp => cp.name === 'Free clustering plan').sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
    hasActiveSubscription () {
      if (!this.activeWorkspace) return false
      const activeSubscription = this.activeWorkspace.type === 'saas' && this.activeWorkspace.subscription_active
      if (activeSubscription) this.keywordLimit = this.keywordLimitPaid
      return activeSubscription
    },
    hasActiveTrial () {
      if (!this.activeWorkspace) return false
      const workspaceCreated = new Date(this.activeWorkspace.created_at).getTime() // in UTC
      const now = new Date().getTime() + new Date(new Date().getTimezoneOffset() * 60 * 1000).getTime() // time now in UTC
      const trialLength = 24 * 60 * 60 * 1000 // 24 hours
      const activeTrial = this.activeWorkspace.type === 'saas' && !this.activeWorkspace.subscription_active && now - workspaceCreated < trialLength
      if (this.showTrialPopup === null) this.showTrialPopup = activeTrial
      return activeTrial
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    ...mapActions([
      'loadContentplans',
      'startClusterer',
      'getClustererStatus',
      'createStripeCheckoutSession',
      'updateContentplan',
      'resetContentplan'
    ]),
    async loadData () {
      this.isLoading.contentplans = true
      try {
        await this.loadContentplans({
          workspaceId: this.workspaceId,
          type: 'saas-cluster'
        })
      } catch (e) {
        console.error(e)
      }

      this.isLoading.contentplans = false
    },
    countKeywords () {
      if (!this.keywords?.length) return 0
      // count keywords one per line
      return this.keywords?.split(/[\r\n\t]+/)?.filter(k => k).length || 0
    },
    async runClusterer () {
      try {
        if (!this.keywords?.length) return
        this.isLoading.clusterer = true

        // Turn this.keywords string into array
        const keywords = this.keywords.split(/[\r\n\t]+/)?.filter(k => k?.length)

        const result = await this.startClusterer({
          keywords,
          country: this.country,
          language: this.language,
          serpOverlap: this.serpOverlap,
          domain: this.domain,
          workspaceId: this.workspaceId,
          type: 'saas-cluster'
        })

        if (!result) {
          this.isLoading.clusterer = false
          console.log('No contentplan_id returned from startClusterer')
          return
        }
        await this.loadContentplans({
          workspaceId: this.workspaceId,
          type: 'saas-cluster'
        })
        this.reset() // reset form and clear last interval
        this.isLoading.clusterer = false

        // Start new interval. Check status every 5 seconds
        this.interval = setInterval(async () => {
          await this.clustererStatus()
        }, 5000)
      } catch (err) {
        console.log(err)
        this.isLoading.clusterer = false
      }
    },
    async clustererStatus () {
      await this.loadContentplans({
        workspaceId: this.workspaceId,
        type: 'saas-cluster'
      })
      // if all contentplans are done, stop interval
      if (this.contentplans.every(cp => cp.labelling_ended && cp.csv_url)) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    reset () {
      clearInterval(this.interval)
      this.interval = null
      this.keywords = null
      this.country = 'USA'
      this.language = 'en'
    },
    async redirectToCheckout () {
      this.isLoading.checkout = true
      const response = await this.createStripeCheckoutSession({
        workspaceId: this.workspaceId
      })
      const redirectURL = response?.url
      this.isLoading.checkout = false
      // redirect to stripe checkout
      if (redirectURL) {
        window.location.href = redirectURL
      }
    },
    async reclusterContentplan () {
      const contentplanId = this.reclusterContentplanId
      this.isLoading.recluster = true
      try {
        await this.updateContentplan({
          contentplanId,
          minimum_score: this.reclusterOverlap
        })
        await this.resetContentplan({
          contentplanId,
          recluster: true
        })
        await this.loadContentplans({
          workspaceId: this.workspaceId,
          type: 'saas-cluster'
        })
        this.reset() // reset form and clear last interval
        this.reclusterShowDialog = false
        this.isLoading.recluster = false

        // Check status every 5 seconds
        this.interval = setInterval(async () => {
          this.clustererStatus()
        }, 5000)
      } catch (e) {
        console.error(e)
      }
    },
    showReclusterDialog (contentplanId) {
      this.reclusterContentplanId = contentplanId
      this.reclusterShowDialog = true
    },
    closeReclusterDialog () {
      this.reclusterContentplanId = null
      this.reclusterShowDialog = false
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    SaaSCTA
  }
}
