import { mapGetters, mapActions } from 'vuex'
import parseData from '@/utils/parseData'
import MarkdownEditor from '@/components/MarkdownEditor/MarkdownEditor.vue'

export default {
  name: 'Article',
  data () {
    return {
      isLoading: { article: false },
      title: null,
      description: '',
      research: '',
      draft: '',
      finalDraft: '',
      tableArticleKeywords: {
        isLoading: false,
        headers: [{ text: 'Keywords', value: 'keyword' }, { text: 'Search Volume', value: 'estimated_search_volume' }],
        sortBy: 'google_impressions_last',
        descending: true
      },
      tableCompetitors: {
        isLoading: false,
        headers: [{ text: 'Top 5 Competitors', value: 'url' }]
      },
      notesTabs: [
        { text: 'Brief', value: 'brief' },
        { text: 'Research', value: 'research' },
        { text: 'Draft', value: 'draft' },
        { text: 'Final Article', value: 'finalDraft' }
      ],
      notesTab: 3
    }
  },
  async mounted () {
    await this.loadData()
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'article',
      'keywords',
      'articleExternalUrls'
    ]),
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    sharingKey () {
      return this.$route.params.sharingKey
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'loadArticle',
      'loadArticleKeywords',
      'loadArticleExternalUrls'
    ]),
    async loadData () {
      this.isLoading.article = true
      try {
        await Promise.all([
          this.loadArticleKeywords({ articleId: this.articleId, sharingKey: this.sharingKey }),
          this.loadArticleExternalUrls({ articleId: this.articleId, sharingKey: this.sharingKey }),
          this.loadArticle({ articleId: this.articleId, sharingKey: this.sharingKey })
        ])
      } catch (e) {
        console.error(e)
      }
      this.isLoading.article = false

      this.description = this.article?.description || ''
      this.research = this.article?.research || ''
      this.draft = this.article?.draft || ''
      this.finalDraft = this.article?.final || ''
      this.title = this.article?.title || ''
      document.title = this.article?.title + ' | ContentGecko'
    },
    copyMarkdown () {
      const markdown = this.notesTab === 0 ? this.description : this.notesTab === 1 ? this.research : this.notesTab === 2 ? this.draft : this.finalDraft
      navigator.clipboard.writeText(markdown)
    }
  },
  components: { MarkdownEditor }
}
